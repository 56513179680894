<template>
    <div class="fast-body-modo-aluno">
        <!-- fd-wrap -->
        <div class="fd-app-wrap">
            <div class="container fast-plataforma-iuea-hide">
                <!-- breadcrumb -->
                <nav>
                    <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide mb-0">
                        <li class="fd-app-breadcrumb-item">
                            <a href="/minhas-plataformas" class="aluno_font_color"
                                @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
                        </li>
                        <li class="fd-app-breadcrumb-item">
                            <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)">Plataforma {{
                                    $store.state.fastPlataforma.nome_plataforma }}</a>
                        </li>
                        <li class="fd-app-breadcrumb-item">
                            <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">Modo
                                aluno</a>
                        </li>
                        <li class="fd-app-breadcrumb-item">
                            <a class="aluno_font_color"
                                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria'"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria')">Secretaria</a>
                        </li>
                        <li class="fd-app-breadcrumb-item active">
                            <a href="#">Demonstrativo IR</a>
                        </li>
                    </ul>
                    <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
                        <li class="fd-app-breadcrumb-item">
                            <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'">Menu
                                principal</a>
                        </li>
                        <li class="fd-app-breadcrumb-item active">
                            <a class="aluno_font_color" href="#">Demonstrativo IR</a>
                        </li>
                    </ul>
                </nav>
                <!-- /breadcrumb -->
            </div>
            <div class="fd-mode-ead-content mt-0">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="mt-3 mb-4">
                                <a class="btn-novo btn-red-hollow-novo"
                                    :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria'"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria')">
                                    <b-icon-arrow-return-left /> Voltar
                                </a>
                            </div>
                            <!-- fd-app-welcome -->
                            <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                                <h2 class="aluno_font_color mb-0">
                                    <span>Demonstrativo</span> para Imposto de Renda
                                </h2>
                                <p class="aluno_font_color my-0">Solicite um demonstrativo para sua declaração de imposto de renda!</p>
                                <div>
                                    <img :src="require('@/assets/images/separador.png')
                                        ">
                                </div>
                            </section>
                            <!-- /fd-app-welcome -->
                            <section class="mt-3">
                                <!-- Conteúdo principal -->
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="text-left col-lg-7 col-md-7 col-sm-12">
                                            <label class="text-secondary mb-0 mx-1 row">
                                                <small class="pl-0 ml-0 pl-0 col-8"><b>Período:</b></small>
                                                <!-- <small class="col-4 text-right mr-0 pr-0" role="button"
                                                v-if="fastAlunoExtratoParcelas.data_recebimento_start || fastAlunoExtratoParcelas.data_recebimento_end"
                                                @click="fastAlunoExtratoParcelas.data_recebimento_start = '', fastAlunoExtratoParcelas.data_recebimento_end = ''">Limpar
                                                    <b-icon icon="x-circle" scale="1.1"
                                                        variant="danger"></b-icon></small> -->
                                            </label>
                                            <select name="selecionaPeriodo" v-model="fastPeriodo.anoSelecionado" class="form-control-sm">
                                                <option v-for="(ano, index) in fastPeriodo.anosDisponiveis" :key="index" :value="ano">{{ ano }} </option>
                                            </select>
                                            <!-- <div class="d-flex">
                                                <b-form-datepicker class="align-self-start" size="sm"
                                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                                                    v-model="fastAlunoExtratoParcelas.data_recebimento_start"
                                                    locale="pt-BR"
                                                    label-no-date-selected="Selecionar data"
                                                    placeholder="Selecionar data"
                                                    label-help="Use o cursor para selecionar"></b-form-datepicker>
                                                <small class="align-self-start pt-1 px-2">até</small>
                                                <b-form-datepicker class="align-self-start" size="sm"
                                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                                                    v-model="fastAlunoExtratoParcelas.data_recebimento_end"
                                                    locale="pt-BR"
                                                    label-no-date-selected="Selecionar data"
                                                    placeholder="Selecionar data"
                                                    label-help="Use o cursor para selecionar"></b-form-datepicker>
                                            </div> -->
                                        </div>
                                        <div class="col-sm-12 col-md-2 col-lg-2 offset-lg-3 offset-md-3 d-flex align-items-center mt-lg-0 mt-md-0 mt-3">
                                            <a href="#" class="btn-novo btn-blue-hollow p-2 col-sm-12 text-center" @click="geraExtratoParcelas()">
                                                <b-icon-plus-circle />
                                                Emitir
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fd-app-table-novo col-12 p-0 mt-4">
                                        <div class="col-12">
                                            <h5 class="mb-0 pl-3 py-1">Extrato de parcelas</h5>
                                        </div>
                                        <div class="col-12 table-responsive m-0 p-0">
                                            <table class="table table-sm text-nowrap">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th>
                                                            <small class="font-weight-bold">Referência</small>
                                                        </th>
                                                        <th>
                                                            <small class="font-weight-bold">Download</small>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="this.$store.state.fastCarregando">
                                                    <tr>
                                                        <td colspan="5" class="text-center">
                                                            Carregando extrato(s)...
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else-if="this.fastAlunoRespostaExtratoParcelas.parcelas == null">
                                                    <tr>
                                                        <td colspan="5" class="text-center" style="color:#008BD0;">
                                                            Não foram gerados extrados para o período selecionado
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else-if="this.fastAlunoRespostaExtratoParcelas.parcelas.length">
                                                    <tr class="text-center">
                                                        <td>
                                                            {{this.fastAlunoRespostaExtratoParcelas.parcelas.length }} parcela(s) encontrada(s)
                                                        </td>
                                                        <td>
                                                            <a :href="ajustaLinkApiAntiga(this.fastAlunoRespostaExtratoParcelas.link_pdf)" target="_blank" class="btn-novo btn-sm btn-primary pt-0 pb-0 mr-2">
                                                                <small class="text-light"><b-icon-download/> Baixar declaração</small>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="5" class="text-center" style="color:#008BD0;">
                                                            Nenhum extrato gerado
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Conteúdo principal -->
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /fd-wrap -->

    </div>
</template>
    
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    // Nome do componente
    name: "HomeInternoAlunoSecretariaExtratoParcelas",
    // Componentes
    components: {
    },
    // Carrega métodos globais
    mixins: [methods],
    // Variáveis locais
    data: function () {
        return {
            modalWidthSm: this.isMobile() ? "80%" : "30%",
            modalWidthMd: this.isMobile() ? "90%" : "50%",
            modalWidthLg: this.isMobile() ? "100%" : "90%",
            fastCarregandoAcao: false,
            fastTemplate: settings.fastTemplate,
            fastAlunoExtratoParcelas: {
                id_plataforma: "",
                nome_aluno: "",
                id_pessoa_aluno: "",
                nome_responsavel: "",
                id_invoice: "",
                data_registro_start: "",
                data_registro_end: "",
                data_vencimento_start: "",
                data_vencimento_end: "",
                data_recebimento_start: "",
                data_recebimento_end: "",
                data_cancelamento_start: "",
                data_cancelamento_end: "",
                status_vencido: false,
                status_pago: false,
                status_pendente: false,
                status_cancelado: false,
                id_matriculador: "",
            },
            fastAlunoRespostaExtratoParcelas: {
                link_pdf: "",
                parcelas: [],
            },
            fastPeriodo: {
                anosDisponiveis: [],
                anoSelecionado: "",
            },
        };
    },
    computed: {

    },
    // Após carregar componente
    mounted: function () {
        // Captura ID da plataforma pela URL
        if (this.$route.params.id_plataforma) {
            // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
            this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
                .then(() => {
                    console.log("this.$store.state.fastPermissoes", this.$store.state.fastPermissoes)
                    // Iniciar aqui
                    this.fastPeriodo.anosDisponiveis = [...Array(4)].map((a, b) => new Date().getFullYear() + b-3)
                    this.$store.state.fastCarregando = false;
                })
                .catch((e) => {
                    this.exibeToasty(e, "error");
                    this.$store.state.fastCarregando = false;
                });
        }
    },
    // Espaço reservado para os métodos
    methods: {
        async geraExtratoParcelas () {
            this.$store.state.fastCarregando = true;
            let obj = {
                IdPlataforma: this.$route.params.id_plataforma,
                DataRecebimentoStart: this.fastPeriodo.anoSelecionado + "-01-01T00:00:00",
                DataRecebimentoEnd: this.fastPeriodo.anoSelecionado +  "-12-31T00:00:00",
                StatusPago: true,
            };
            let erros = [];
            // if (!this.fastAlunoExtratoParcelas.data_recebimento_start) erros.push("A data inicial é obrigatória");
            // if (!this.fastAlunoExtratoParcelas.data_recebimento_end) erros.push("A data final é obrigatória");
            // if (this.fastAlunoExtratoParcelas.data_recebimento_end < this.fastAlunoExtratoParcelas.data_recebimento_start) erros.push("A data final deve ser maior do que a data inicial");
            if (!this.fastPeriodo.anoSelecionado) erros.push("O período deve ser selecionado");
            if (erros.length) {
                this.$store.state.fastCarregando = false;
                erros.forEach((e) => this.exibeToasty(e, "error"));
            } else {
                //api/fast_tesouraria_invoice_parcela/gera_extrato_parcelas
                this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/gera_extrato_parcelas" + "?IdPlataforma=" + obj.IdPlataforma + "&DataRecebimentoStart=" + obj.DataRecebimentoStart + "&DataRecebimentoEnd=" + obj.DataRecebimentoEnd + "&StatusPago=" + obj.StatusPago)
                .then((res) => {
                    if(res){
                        this.fastAlunoRespostaExtratoParcelas.link_pdf = res.link_pdf;
                        this.fastAlunoRespostaExtratoParcelas.parcelas = res.parcelas;
                        this.$store.state.fastCarregando = false;
                        if(this.fastAlunoRespostaExtratoParcelas.parcelas == null){
                            this.$store.state.fastCarregando = false;
                            this.exibeToasty("Nenhuma parcela encontrada", "info");
                        } else {
                            this.$store.state.fastCarregando = false;
                            this.exibeToasty("Documento gerado com sucesso", "success")
                        }
                    } else {
                        this.$store.state.fastCarregando = false;
                        this.exibeToasty("Erro ao gerar documento", "error");
                    }
                }).catch((e) => {
                    this.exibeToasty(e, "error")
                })
            }
        },
    },
};
</script>
    
<style scope></style>